import {StoryData} from "./storyBuilder";

export async function postStory(media: String, text: String, context: string): Promise<StoryUpdateResponse> {
    const response = await fetch('/api/story/', {
        method: "POST",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            media,
            text,
            context
        }),
    });

    return await processResponse(response) as StoryUpdateResponse;
}

export async function putStory(storyId: number, media: String, text: String, context: string): Promise<StoryUpdateResponse> {
    const response = await fetch(`/api/story/${storyId}`, {
        method: "PUT",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            media,
            text,
            context
        }),
    });

    return await processResponse(response) as StoryUpdateResponse;
}

export async function deleteStory(storyId: number): Promise<StoryUpdateResponse> {
    const response = await fetch(`/api/story/${storyId}/delete`, {
        method: "DELETE",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
        },
    });

    return await processResponse(response) as StoryUpdateResponse;
}


export async function getStories(context: string) {
    const response = await fetch(`/api/story/list?context=${context}`, {
        method: "GET",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
        },
    });

    return await processResponse(response);
}

export async function getStory(storyId: number): Promise<StoryData> {
    const response = await fetch(`/api/story/${storyId.toString()}`, {
        method: "GET",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
        },
    });

    return await processResponse(response) as StoryData;
}

async function processResponse(response: Response): Promise<Object> {
    const responseData = await response.json();

    // @ts-ignore
    if (responseData.statusCode && responseData.statusCode > 202) {
        throw responseData;
    }

    return responseData;
}

export interface SiteUserData {
    username: string
    fullname: string
    id: string;
    profileImage: string
    oid: number;
    storyCreatePermission: boolean;
    isAdmin: boolean;
}


export interface StoryUpdateResponse {
    statusText: string;
    id: number;
}

export function getSiteCurrentUser(): SiteUserData|null {
    // @ts-ignore
    return window.currentUser ? window.currentUser as SiteUserData : null;
}
