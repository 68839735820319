import * as React from 'react';
import ListItem from "./ListItem";
// @ts-ignore
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Stories from 'react-insta-stories';
import {getSiteCurrentUser, getStories} from "../havervagyApi";
import {buildStory, getStoryRenderers, StoryData} from "../storyBuilder";

const defaultSliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    // slidesToShow: 16,
    //slidesToScroll: 4, TODO: scolling bug?
    arrows: false,
    variableWidth: true,
    // responsive: [
    //     {
    //         breakpoint: 2300,
    //         settings: {
    //             slidesToShow: 16,
    //         }
    //     },
    //     {
    //         breakpoint: 1920,
    //         settings: {
    //             slidesToShow: 13,
    //         }
    //     },
    //     {
    //         breakpoint: 1600,
    //         settings: {
    //             slidesToShow: 10,
    //         }
    //     },
    //     {
    //         breakpoint: 1280,
    //         settings: {
    //             slidesToShow: 8,
    //         }
    //     },
    //     {
    //         breakpoint: 800,
    //         settings: {
    //             slidesToShow: 6,
    //         }
    //     },
    //     {
    //         breakpoint: 600,
    //         settings: {
    //             slidesToShow: 4,
    //         }
    //     }
    // ]
};

function generateEmptyList(): Array<StoryData> {
    const l: Array<StoryData> = [];
    for (let i = -2; i > -4; i--) {
        l.push({...emptyStoryPlaceholder, id: -1} as StoryData);
    }

    return l;
}

function buildStories(data: Array<Object>): Array<Object> {
    return data.map(d => buildStory(d as StoryData));
}

interface ListProps {
    context: string;
    allowAdd: boolean;
    onCreateClick: CallableFunction;
    onEditClick: CallableFunction;
}

const defaultCreateStoryPlaceholder = StoryData.create().setId(-1);
const emptyStoryPlaceholder = StoryData.create().setId(0).setMedia('/img/bg-input.gif');

const List = (props: ListProps) => {
    const [loading, setLoading] = React.useState(true);
    const [list, setList] = React.useState([]);
    const [renderSlider, setRenderSlider] = React.useState(false);
    const [showPresentation, setShowPresentation] = React.useState(false);
    const [currentStoryIndex, setCurrentStoryIndex] = React.useState(0);
    const [sliderSettings, setSliderSettings] = React.useState(defaultSliderSettings);
    const [createStoryPlaceholder, setCreateStoryPlaceholder] = React.useState(
        defaultCreateStoryPlaceholder.setUser(getSiteCurrentUser())
    );

    console.log('getSiteCurrentUser()',getSiteCurrentUser());

    const [storiesSettings, setStoriesSettings] = React.useState({
        stories: [],
        width: window.innerWidth,
        height: window.innerHeight,
        keyboardNavigation: true,
        onAllStoriesEnd: () => {
            setShowPresentation(false)
        },
        onStoryStart: (currentIndex: number) => {
            setCurrentStoryIndex(currentIndex);
        },
        currentIndex: 0,
        preloadCount: 2,
        renderers: getStoryRenderers(),
    });

    function reconfigureSlider(itemsCount: number) {
        const settings = {...defaultSliderSettings};
        // settings.slidesToShow = settings.slidesToShow > itemsCount ? itemsCount : settings.slidesToShow;
        // settings.responsive = settings.responsive.map((responsive: any) => {
        //     responsive.settings.slidesToShow = responsive.settings.slidesToShow > itemsCount ? itemsCount : responsive.settings.slidesToShow;
        //     return responsive;
        // })
        // console.log('new slider settings', settings);
        setSliderSettings(settings);
    }

    async function fetchData() {
        setLoading(true);
        const response = await getStories(props.context);

        // @ts-ignore
        const data: Array<StoryData> = response.data;
        setList(data);
        reconfigureSlider(data.length + (props.allowAdd ? 1 : 0));
        setRenderSlider(true);
        setLoading(false);
    }

    function getStoryIdByIndex(index: number): number|null {
        return list[index].id;
    }

    React.useEffect(() => {
        fetchData();
    }, []);

    React.useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => event.key === 'Escape' && setShowPresentation(false);
        window.addEventListener('keydown', handleEsc);

        return () => window.removeEventListener('keydown', handleEsc);
    }, []);

    React.useEffect(() => {
        const url = new URL(document.location.origin);
        if (showPresentation) {
            url.searchParams.set('presentingStory', getStoryIdByIndex(currentStoryIndex).toString());
            document.location.hash = url.search;
        } else if (document.location.hash) {
            document.location.hash = '';
        }
    }, [currentStoryIndex, showPresentation])

    function presentStoriesFromIndex(index: number) {
        setStoriesSettings({
            ...storiesSettings,
            stories: list.map(e => buildStory(e as StoryData, null)),
            currentIndex: index,
        });

        setShowPresentation(true);
    }

    function onListItemClick(index: number) {
        presentStoriesFromIndex(index);
    }

    function onCreateClick() {
        props.onCreateClick();
    }

    function editCurrentStory() {
        props.onEditClick(getStoryIdByIndex(currentStoryIndex));
    }

    function canEditCurrentStory() {
        const currentUser = getSiteCurrentUser();
        return list[currentStoryIndex].userId == currentUser.id || currentUser.isAdmin;
    }

    return (
        <div className={'story-list col-md-12' + (loading ? ' loading' : '')}>
            {renderSlider && <Slider {...sliderSettings}>
                    {props.allowAdd && <ListItem key={'create'} data={defaultCreateStoryPlaceholder} onShowStory={() => onCreateClick()}></ListItem>}
                    {list.map((entry: StoryData, index) => (
                        <ListItem key={entry.id} data={entry} onShowStory={() => onListItemClick(index)}></ListItem>
                    ))}
                </Slider>
            }

            {showPresentation ? <div className="story-presentation-wrapper story-presentation-wrapper--fullscreen">
                <div className="controlsContainer">
                    {/*{canEditCurrentStory() && <button onClick={() => editCurrentStory()} className={"btn btn-info"}>Szerkesztés</button>}*/}
                    <button onClick={() => setShowPresentation(false)} className={"btn btn-secondary closeButton"} aria-label="Bezárás" title="Bezárás"><i className="fa fa-times"></i></button>
                </div>
                <div className="stories-component-wrapper">
                    <Stories {...storiesSettings} />
                </div>
            </div> : ''}
        </div>
    );
}

export default List;
