import * as React from 'react';
import {ChangeEvent} from "react";
import Stories from "react-insta-stories";
import ImageRenderer from 'react-insta-stories';
import YoutubeRenderer from './renderer/Youtube'
import {SiteUserData} from "./havervagyApi";


export class StoryData {
    id: number|null = null;
    userId: string = '';
    userName: string = '';
    context: string = '';
    userProfileImage: string = '';
    uploadedAt: string = '';
    type: string = 'image';
    media: string = '';
    mediaThumbnail: string = '';
    text: string = '';

    static create(): StoryData {
        return new StoryData()
    }

    public setId(id: number): StoryData {
        this.id = id;

        return this;
    }

    public setUser(user: SiteUserData): StoryData {
        this.userName = user.fullname;
        this.userProfileImage = user.profileImage;

        return this;
    }

    public setUploadedAt(uploadedAt: string): StoryData {
        this.uploadedAt = uploadedAt;

        return this;
    }

    public setMedia(media: string): StoryData {
        this.media = media;
        if (!this.mediaThumbnail) {
            this.mediaThumbnail = media;
        }

        return this;
    }
}


function nl2br(str: string) {
    const breakTag = '<br>';
    return str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
}


export function buildStory(data: StoryData, editableCallback: CallableFunction|null = null): Object {
    let story = {
        id: data.id,
        url: data.media,
        type: data.type || 'image',
        duration: (data.type === 'youtube' ? 10 * 60 : 10) * 1000, // ms
        header: {
            heading: data.userName,
            subheading: data.uploadedAt,
            profileImage: data.userProfileImage,
        },
        userContent: 'YES',
        userContentClasses: '',
        seeMoreCollapsed: () => {
            return (<p contentEditable={!!editableCallback}
                       onInput={e => editableCallback(e.currentTarget.innerHTML)}
                       className={"story--user-content"}
                       dangerouslySetInnerHTML={{__html: nl2br(data.text || '')}}></p>)
        },
        seeMore: () => {
            return (<p>see more</p>)
        },
    };

    // hack it to display youtube
    // story.type = 'youtube';
    // story.url = 'https://www.youtube.com/watch?v=gt5EFihYxlc';

    console.log('STORY BUILDER', story);
    return story;
};

export function getStoryRenderers() {
    const renderers = [];
    // renderers.push(ImageRenderer);
    renderers.push(YoutubeRenderer);
    return renderers;
}

// embed code
// <iframe width="560" height="315" src="https://www.youtube.com/embed/qzLsiQySt9U?si=mTKdard4LdLuvA-m" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
