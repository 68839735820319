import * as React from 'react';
import {StoryData} from "../storyBuilder";

interface ListItemProps {
    data: StoryData;
    onShowStory: CallableFunction
}

const ListItem = (props: ListItemProps) => {
    let imageSrc: string;
    let userProfileImage: string;

    const isNewPlaceholder = props.data.id === -1;

    if (isNewPlaceholder) {
        imageSrc = props.data.userProfileImage;
    } else {
        imageSrc = props.data.mediaThumbnail;
        userProfileImage = props.data.userProfileImage
    }

    console.log('ListItem', { isNewPlaceholder, imageSrc, userProfileImage });


    const username = props.data;

    const showStory = function() {
        props.onShowStory();
    }

    return (
        <div key={props.data.id} className={'story-list-item' + (isNewPlaceholder ? ' story-list-item--create-item' : '') }
             onClick={() => !isNewPlaceholder && showStory()}>
            <div className="card">
                <div className="card-body" style={{backgroundImage: `url("${imageSrc}")`}}>
                    {!isNewPlaceholder && <div className="user-picture"
                               style={{backgroundImage: `url("${userProfileImage}")`}}/>
                    }
                    {!isNewPlaceholder && <p className="card-text user-name">{props.data.userName}</p> }
                </div>
                {isNewPlaceholder && <div className="card-footer">
                    <button className='add-button' onClick={() => showStory()}><i className="fa fa-plus-circle"></i></button>
                    <div className='add-text'>Új történet</div>
                </div>}
            </div>
        </div>
    )
};

export default ListItem;
