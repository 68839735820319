import * as React from 'react';
import {createRoot} from 'react-dom/client';

import ListItem from './Components/ListItem';
import List from './Components/List';
import Editor from './Components/Editor';
import './stories-style.scss';
import {getSiteCurrentUser} from "./havervagyApi";



function StoriesPresentationApp() {
    function onCreateClick() {
        const context = document.location.pathname;
        const targetUrl = new URL(document.location.origin + '/stories/create');
        targetUrl.searchParams.set('context', context);
        document.location.assign(targetUrl);
    }

    function onEditClick(storyId: number) {
        const targetUrl = new URL(document.location.origin + `/stories/edit`);
        targetUrl.searchParams.set('storyId', storyId.toString());
        document.location.assign(targetUrl);
    }


    return (
        <div className="row">
            <List
                context={window.location.pathname}
                allowAdd={getSiteCurrentUser().storyCreatePermission}
                onCreateClick={() => onCreateClick()}
                onEditClick={(storyId: number) => onEditClick(storyId)}
            />
        </div>
    );
}

function StoriesEditorApp() {

    const requestUrl = new URL(document.location.href);
    const requestContext = requestUrl.searchParams.get('context');
    const requestReturnUrl = requestUrl.searchParams.get('return');
    const requestStoryId = parseInt(requestUrl.searchParams.get('storyId')) || null;

    function onCompleted(storyId: number, storyContext: string) {
        if (requestReturnUrl) {
            document.location.assign(requestReturnUrl);
        } else {
            const returnUrl = new URL(document.location.origin + storyContext);
            if (storyId > 0) {
                returnUrl.searchParams.set('storyId', storyId.toString());
            } else {
                returnUrl.searchParams.set('storyId', '');
            }

            document.location.assign(returnUrl);
        }
    }



    return (
        <Editor context={requestContext}
                storyId={requestStoryId}
                onCompleted={(createdId: number, context: string) => onCompleted(createdId, context)} />
    )
}


const listContainer = document.getElementById('stories-app');
if (listContainer) {
    createRoot(listContainer).render(<StoriesPresentationApp/>);
}
const editorContainer = document.getElementById('stories-editor-app');
if (editorContainer) {
    createRoot(editorContainer).render(<StoriesEditorApp/>);
}

