import Stories from "react-insta-stories";
import * as React from "react";

interface SidebarLayoutProps {
    children: string | React.ReactNode
    sidebar: string | React.ReactNode,
}

const SidebarLayout = (props: SidebarLayoutProps) => {
    return (
        <div className={'row'}>
            <div className="col-sm-12 col-md-12 col-lg-8 ">
                {props.children}
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4 story-layout-sidebar">
                <>{props.sidebar}</>
            </div>
        </div>
    )
}

export default SidebarLayout;
