import * as React from "react";
import {Renderer, Tester} from "react-insta-stories/dist/interfaces";
import {WithHeader, WithSeeMore} from "react-insta-stories";
import {YT} from '../global'
import type { Window } from '../global'
import {useEffect} from "react";

export const renderer: Renderer = ({story, action, isPaused, config}) => {
    const [loaded, setLoaded] = React.useState(false);
    const [youtubeApiLoaded, setYoutubeApiLoaded] = React.useState(false);
    const [player, setPlayer] = React.useState(null);
    const {width, height, loader, storyStyles} = config;
    let computedStyles = {
        ...styles.storyContent,
        ...(storyStyles || {}),
    };

    useEffect(() => {
        if (!loaded || !player) {
            return;
        }

        if (isPaused) {
            player.pauseVideo();
        } else {
            player.playVideo();
        }
    }, [isPaused]);

    const imageLoaded = () => {
        setLoaded(true);
        action("play");
    };

    function onPlayerReady(event: any): void {
        imageLoaded();
        console.log('onPlayerRead', 'now calling playVideo()', event.target);
        event.target.mute(); // required for autoplay

        setTimeout(() => {event.target.playVideo()}, 100);
    }

    var done = false;

    function onPlayerStateChange(event: any): void {
        const YT = getYT();

        if (event.data == YT.PlayerState.BUFFERING) {
            console.log('BUFFERING');
        }

        if (event.data == YT.PlayerState.UNSTARTED) {
            console.log('UNSTARTED');
        }

        if (event.data == YT.PlayerState.PLAYING && !done) {
            console.log('PLAYING');
            done = true;
        }

        if (event.data == YT.PlayerState.ENDED) {
            console.log('ENDED');
            stopVideo();
        }
    }

    function stopVideo() {
        player.stopVideo();
    }

    function getYT(): any {
        return ((window as unknown) as Window).YT;
    }

    function initVideo() {
        const videoUrl = story.url.match(/youtube\.com\/watch\?v\=(.*)/);
        if (!videoUrl) {
            throw 'Unable to parse video url: ' + story.url;
        }

        const player = new (getYT().Player)('story_yt_player', {
            height: '100%',
            width: '100%',
            videoId: videoUrl[1],
            playerVars: {
                'playsinline': 1,
                'rel': 0,
                'modestbranding': 1,
                'egm': 0,
                'showinfo': 0,
                'showsearch': 0,
                'autoplay': 1,
                'controls': 0,
                'iv_load_policy': 3,
                'loop': 1,
                'cc_load_policy': 0,
                'mute': 1,
            },
            events: {
                'onReady': onPlayerReady,
                'onStateChange': onPlayerStateChange
            }
        });
        setPlayer(player);
    }

    function onYouTubeIframeAPIReady() {
        setYoutubeApiLoaded(true);

        console.log('onYouTubeIframeAPIReady', getYT());
        initVideo();
    }

    function loadYoutubeApi() {
        (window as any)['onYouTubeIframeAPIReady'] = onYouTubeIframeAPIReady;
        console.log('loadYoutubeApi', window);

        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];

        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        setYoutubeApiLoaded(true);
    }

    React.useEffect(() => {
        let youtubeApiAlreadyLoaded = getYT() && getYT().loaded;
        console.log('MOUNTED', 'YT', getYT(), youtubeApiAlreadyLoaded);
        setYoutubeApiLoaded(youtubeApiAlreadyLoaded)
        if (!youtubeApiAlreadyLoaded) {
            loadYoutubeApi();
        } else {
            initVideo();
        }
    }, [])

    return (
        <WithHeader {...{story, globalHeader: config.header}}>
            <WithSeeMore {...{story, action}}>
                <div style={{ height: '100%' }}>
                    <div id="story_yt_player" style={{ border: '1px solid red', paddingTop: '50px' }}></div>

                    {!loaded && (
                        <div
                            style={{
                                width: width,
                                height: height,
                                position: "absolute",
                                left: 0,
                                top: 0,
                                background: "rgba(0, 0, 0, 0.9)",
                                zIndex: 9,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "#ccc",
                            }}
                        >
                            {/*{loader || <Spinner/>}*/}
                        </div>
                    )}
                </div>
            </WithSeeMore>
        </WithHeader>
    );
};

const styles = {
    story: {
        display: "flex",
        position: "relative",
        overflow: "hidden",
    },
    storyContent: {
        width: "auto",
        maxWidth: "100%",
        maxHeight: "100%",
        margin: "auto",
    },
};

export const tester: Tester = (story) => {
    return {
        condition: !story.content && (!story.type || story.type === "youtube"),
        priority: 2,
    };
};

export default {
    renderer,
    tester,
};
