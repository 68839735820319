import * as React from 'react';
import {ChangeEvent, createRef, useEffect, useState} from 'react';
import Stories from 'react-insta-stories';
import {buildStory, StoryData, getStoryRenderers} from "../storyBuilder";
import {loadFile} from "../fileUtils";
import {deleteStory, getSiteCurrentUser, getStory, postStory, putStory, StoryUpdateResponse} from "../havervagyApi";
import SidebarLayout from "./SidebarLayout";
import {Story} from "react-insta-stories/dist/interfaces";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;




const defaultMedia = '/img/story-default.png';
const storyStyles = {
    // border: '1px solid green',
}
const storyContainerStyles = {
    // border: '1px solid red',
}

interface EditorProps {
    onCompleted: CallableFunction;
    context: string;
    storyId: number|null;
}

const Editor = (props: EditorProps) => {
    const fileInput = createRef<HTMLInputElement>();
    const [loading, setLoading] = useState(false);

    const [media, setMedia] = useState(defaultMedia);
    const [isDirty, setIsDirty] = useState(false);
    const [context, setContext] = useState(props.context || '');
    const [errorMessage, setErrorMessage] = useState('');
    const [data, setData] = useState({} as StoryData);
    const [story, setStory] = useState(buildStory(data as StoryData, onTextChange));

    // update "data" once media changes
    useEffect(() => {
        const newUserData = { ...data, media: media } as StoryData;
        setData(newUserData);
        setStory(buildStory(newUserData as StoryData, onTextChange))
        setIsDirty(true);
        setLoading(false);
    }, [media]);

    // load existing story
    useEffect(() => {
        async function loadStoryById() {
            let response;
            try {
                response = await getStory(props.storyId);
            } catch (e) {
                console.error('Failed to load story', e);
                setErrorMessage(e.statusText);
                setLoading(false);

                throw e;
            }

            setData(response);
            setStory(buildStory(response as StoryData, onTextChange));
            setContext(response.context);
            setIsDirty(false);
            setLoading(false);
        }

        if (!!props.storyId) {
            setLoading(true);
            loadStoryById();
        }
    }, [props.storyId]);



    async function onFileChange(e: ChangeEvent) {
        setLoading(true);
        // @ts-ignore
        setMedia(await loadFile(e.target.files[0]));
    }

    function onTextChange(newText: string) {
        setData(data => {
            return { ...data, text: newText } as StoryData;
        });
        setIsDirty(true);
    }

    function reset() {
        setMedia(defaultMedia);
        setData(StoryData.create().setUser(getSiteCurrentUser()).setUploadedAt('-').setMedia(media));
    }

    function cancel() {
        props.onCompleted(null, context);
    }

    async function remove() {
        if (!confirm('Erősítsd meg az eltávolítást!')) {
            return;
        }

        const response: StoryUpdateResponse = await deleteStory(props.storyId);
        if (response.statusText === 'OK') {
            props.onCompleted(null, context);
        }
    }

    async function save() {
        setLoading(true);

        let error: any = null;
        let response: StoryUpdateResponse;


        try {
            const mediaToSend = media === defaultMedia ? null : media;

            response = props.storyId
                ? await putStory(props.storyId, mediaToSend, data.text, context)
                : await postStory(mediaToSend, data.text, context);
        } catch (e) {
            error = e;
        }

        if (!error) {
            if (response.statusText === 'OK') {
                setData(data => { return { ...data, id: response.id} as StoryData});
                setIsDirty(false);

                // do not set loading to false, as the parent component is going to initiate redirect
                props.onCompleted(response.id, context);
            } else {
                error = response;
            }
        }

        window.scrollTo(0,0);

        if (error) {
            console.error('Failed to save story', error);
            setErrorMessage(error.statusText);
            setLoading(false);
        }
    }

    function openFileDialog() {
        fileInput.current.click();
    }

    function storyHasValidMedia() {
        return data.id || (media && media !== defaultMedia);
    }

    return (
        <div className={'story-editor'}>
            <SidebarLayout sidebar={
                <>
                    {/*<div>{JSON.stringify(data)}</div>*/}
                    {/*<code>media={media}</code><br/>*/}
                    {/*<code>story.media={data.media}</code><br/>*/}
                    {/*<code>context={context}</code><br/>*/}
                    {/*<code>storyId={props.storyId}</code>*/}
                    {/*<code>isDirty={JSON.stringify(isDirty)}</code>*/}
                    {/*<code>storyHasValidMedia={JSON.stringify(storyHasValidMedia())}</code>*/}
                    {/*<code>loading={loading}</code>*/}
                    <input type="file" onChange={onFileChange} ref={fileInput} style={{display: 'none'}}/>

                    <button onClick={cancel} className="col-xs-12 mb-2 btn btn-lg btn-default" disabled={loading}>Vissza</button>
                    {!!data.id && <button onClick={remove} className="col-xs-12 mb-2 btn btn-lg btn-warning" disabled={!data.id || loading}>Eltávolítás
                    </button>}
                    <button className="btn btn-lg btn-info col-xs-12 mb-2" onClick={openFileDialog} disabled={loading}>
                        {storyHasValidMedia() ? 'Kép lecserélése' : 'Kép feltöltése'}
                    </button>
                    <button onClick={save} className="btn btn-lg btn-primary col-xs-12 mb-2" disabled={loading || !isDirty || !storyHasValidMedia()}>Mentés</button>
                </>
            }>
                {errorMessage && <div className="col-xs-12"><div className="alert alert-danger">{errorMessage}</div></div>}
                <div
                    className={'story-preview ' + (loading && ' story-preview--loading ') + (!data.text && ' story-preview--no-text ') + ' mt-3'}>
                    <div className="stories-component-wrapper">
                        <Stories stories={[story]} width={'100%'} storyStyles={storyStyles}
                                 storyContainerStyles={storyContainerStyles} renderers={getStoryRenderers()}/>
                    </div>
                </div>
            </SidebarLayout>

            <br/>

        </div>
    );
}


export default Editor;
